@import "~@/styles/variables";
























































































































































































































































































































































































































































































































.add-dose-btn-label {
  color: $pp-blue;
  cursor: pointer;

  &:hover {
    color: darken($pp-blue, 5%);
  }
}

.delete-dose-btn {
  color: $pp-blue;
  opacity: 1;

  &:hover {
    color: darken($pp-blue, 5%);
  }
}

.otc-cost-container {
  background: #e4f5e4;
}

.otc-cost {
  font-size: $f-size-md;
}

.icon-blue {
  & g {
    stroke: $pp-blue;

    &:hover {
      stroke: white;
    }
  }
  height: 30px;
  display: block;
}

.transparent {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

.back-to-search {
  font-size: $f-size-sm;
}
