@import "~@/styles/variables";

























































.add-med-button::v-deep .btn {
  font-size: 1rem;
}
