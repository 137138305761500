@import "~@/styles/variables";
















































































































.add-meds-button {
  $height: 2.6em;
  position: relative;
  z-index: 2;
  height: $height;
  margin-top: $height * -0.51; // .01 for pixel discrepancies
  margin-bottom: $height * -0.51; // .01 for pixel discrepancies
  margin-right: 1em;
  float: right;
}

.top-padding {
  ::v-deep {
    .list-group-item:first-child {
      padding-top: 2em;
    }
  }
}
